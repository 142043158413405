import logo from "./logo.svg"
import "./App.css"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Join the future 👉 {` `}
          <a className="App-link" href="https://remotespace.com" rel="noopener noreferrer">
            remotespace.com
          </a>
        </p>
      </header>
    </div>
  )
}

export default App
